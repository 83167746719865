@import "../../utils/includes.scss";

//
// Fonts
//

$fontFamily: 'nobelregular', sans-serif;
$boldFontFamily: 'nobelbold', sans-serif;
$boldFontWeight: 700;
$titleFontFamily: $boldFontFamily;
$titleFontWeight: $boldFontWeight;
$isUppercase: uppercase;

//
// Colors
//

$textColor: #303030;
$linkColor: #666666;
$labelColor: #666666;
$formColor: #303030;
$utilColor: #666666;
$priceColor: #666666;
$lightColor: #999999;
$btnColorAlpha: #ffffff;
$btnColorBeta: #ffffff;
$btnColorStep: #ffffff;
$titleColor: #303030;
$titleSecondaryColor: #ffffff;
$subtitleColor: #333333;
$subtitleSecondaryColor: #ffffff;
$successColor: #2DB253;
$warningColor: #f7941d;
$errorColor: #ff3526;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;

$bgBtnAlpha: #000000;
$colorBtnAlpha: #fff;
$bgBtnBeta: #ffffff;
$colorBtnBeta: #000000;
$colorBtnOutlineAlpha: #000000;
$colorBtnOutlineBeta: #000000;
$paddingButtonPrimary: 16px 14px;
$paddingButtonSecondary: 11px 15px;
$borderRadiusButton: 0px;

$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$calculatorTabsColor: #16181e;
$calculatorTabsTextColor: #dedede;
$calculatorCloseTabs: #505050;
$calculatorCloseTabsColor: #e3e3e3;
$calculatorMainColor: #ffffff;
$calculatorButton: #f0710a;

$garageBackground: #ffffff;
$garageTextColor: #303030;
$garageCarNoteColor: #f8f8f8;

$bgTopbarLogin: #2b2b2b;
$colorTopbarLogin: #c8c9cb;

$progressBarTitleBG: #666666;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #999999;
$progressBarTitleCloseColor: #ffffff;
$progressBarCloseButtonBG: #363a42 ;
$progressBarCloseButtonColor: #ffffff;
$progressBarSummaryOne: #cccccc;
$progressBarSummaryTwo: #b9c0c2;
$progressBarSummaryTree: #666666;

$paymentCalculatorClosedBG: #171921;
$paymentCalculatorClosedColor: #e8e8e8;
$paymentCalculatorPanelBG: #21232E;
$paymentCalculatorTermWrapperBG: #363a4d;
$paymentCalculatorTermWrapperColor: #aaaaaa;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #252835;
$paymentCalculatorTermColor: #aaaaaa;

// ========
// Styles
// ========

.widget-sr {
    @include clearfix;
    width: 100%;
    &.dealer__park-avenue-lexus-sainte-julie {

    &.sr-progressBar {
        .TransactionSummaryUnit--single:first-child {
            background-color: $progressBarSummaryOne;
        }
        .TransactionSummaryUnit--single:nth-child(2) {
            background-color:  $progressBarSummaryTwo;
        }
        .TransactionSummaryUnit--single:nth-child(3) {
            background-color:  $progressBarSummaryTree;
        }
    }
    .btn-register {
        background-color: $progressBarTitleBG;
    }
    .listing-tile-car-save.has-warning-background{
        background-color: #666666;
    }
    .TransactionActionDetails--wrapper .sr-button-outline-1{
        padding: 15px 14px !important;
        text-transform: uppercase !important;
    }
    .listing-used-header h1.sr-title-1{
        font-family: $fontFamily;
        font-weight: unset;
    }
    .paymentCalculator__title .is-bold,
    .paymentCalculator__buttons #calculator-toggle-button.sr-button-1{
        font-family:'nobelregular', sans-serif;
        font-weight: unset;
        text-transform: uppercase;
    }
    .sr-button-1, .sr-button-2{
        font-family:'nobelregular', sans-serif !important;
        font-weight: unset !important;
        text-transform: uppercase !important;
        box-shadow: none !important;
        transition: all 0.2s ease-in-out;
        font-size: 16px;
        letter-spacing: 1.4px;
    }
    .sr-button-2{
        border: 1px solid #000000 !important;
        color: #000000 !important;
    }
    .btn-register.sr-button-1,
    #calculator-toggle-button.sr-button-1{
        background: #666666;
        border: none !important;
    }
    .vehicleBanner-personsViewed .amount.is-price,
    .vehicleBanner-photos .vehicleBanner-photo-button .is-util{
        color: #ffffff;
    }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}

@include breakpoint(mobile){
    .widget-sr{
        .vehicle-tile-rebate-single{
            background: $bgBtnAlpha;
            .is-price{
                color: $colorBtnAlpha;
            }
            span{
                color: $colorBtnAlpha;
            }
        }
    }
}
